import './about.css'

import { Container, Row, Col } from 'react-bootstrap';

export function About() {
    return ( <
        section id = "about" >
        <
        Container className = "about" >
        <
        Row >
        <
        Col className = "about-left"
        sm = { 6 } > < /Col> <
        Col className = "about-right"
        sm = { 6 } >
        <
        h2 > About Us < /h2> <
        p >
        At Calculated Cloud, our mission is to come alongside your engineering teams and take a deep dive into your existing cloud configuration to optimize and reduce expensive costs related to cloud infrastructure.Our team of experts strategize to create the ultimate plan
        for cost optimization
        while maintaining your business’ performance.We are innovative in approach, our service will elevate your business and set you ahead of the competition. <
        /p> <
        p >
        We don’ t believe in a one size fits all approach and take the time to understand the nuances of your business to provide the best solution.We’ ll be alongside you every step of the way in your deployment to answer questions, solve problems
        while reducing cost, and creating immense value
        for your organization. <
        /p> <
        /Col> <
        /Row> <
        /Container> <
        /section>
    );
}