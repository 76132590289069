import { Navbar, Container, Nav as BNav, Image, Button } from 'react-bootstrap';

import logo from '../../images/logo-white.png';

import './nav.css';

export function Nav() {
    return (
        <Navbar expand="lg"id="home" variant="dark">
            <Container>
                <Navbar.Brand href="#home">
                    <Image src={logo} width="278" height="50"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <BNav className="ms-auto">
                        <BNav.Link href="#home">Home</BNav.Link>
                        <BNav.Link href="#services">Services</BNav.Link>
                        <BNav.Link href="#about">About</BNav.Link>
                        <Button href="#contact" className="button-shaded nav-link">Contact Us</Button>
                    </BNav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
