import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { faPhone, faCloud, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import './services.css';

export function Services() {
    return (<section id="services" >
        <Container >
            <Row >
                <Col xs={12} md={8} className="center" >
                    <h2 > Our Services </h2>
                    <p> At Calculated Cloud, it’s our mission to save your organization from expensive cloud infrastructure costs. Our team of experts is comprised of certified cloud architects and DevSecOps professionals who work with your existing cloud deployment.We pride ourselves in taking a hands-on approach from start to finish and evaluate more than just production environments. We focus on improving cloud deployments throughout the entire software development lifecycle. Our work will decrease your development time
                        while increasing developer productivity, efficiency, and application performance.</p>
                </Col>
            </Row> {renderAllServices()} <Row >
                <Col className="center">
                    <Button href='#contact'>Contact Us</Button>
                </Col>
            </Row>
        </Container>
    </section>
    );
}

function renderAllServices() {
    let services = [{
        icon: faPhone,
        title: 'Expert support',
        content: 'We pride ourselves in providing comprehensive knowledge, experience, and expertise to improve existing deployments and reduce wasted costs. We jump right in and can understand your unique environment quickly and efficiently.',
        color: 'orange'
    },
    {
        icon: faCloud,
        title: 'Flexible and Easy',
        content: 'We take the time to understand your unique application lifecycle and offer creative solutions to save money while streamlining your environment to operate more efficiently. Our solutions solve your specific needs based on your unique circumstances and requirements.',
        color: 'green'
    },
    {
        icon: faDollarSign,
        title: 'Reduction of Costs',
        content: 'Our extensive experience working with clients for cost optimization provides us the ability to understand areas to look at and how to adjust them in order to provide optimal cost reduction.',
        color: 'blue'
    }
    ]
    return (
        <Row className="services"> {
            services.map((item, index) => {
                return renderService(index, item.icon, item.title, item.content, item.color);
            })
        }
        </Row>
    )
}

function renderService(index, icon, title, content, color = "orange") {
    return (<Col className="service-callout" sm={8} lg={4} key={index}>
        <div className="service">
            <div className={`circle ${color}`} > < FontAwesomeIcon icon={icon} />
            </div>
            <div className="details">
                <h4>{title}</h4>
                <p>{content}</p>
            </div>
        </div>
    </Col>
    )
}