import { Button, Col, Container, Row } from 'react-bootstrap';
import './hero.css';

export function Hero() {
    return (
        <section className="hero">
            <div className="light-effect" />
            <Container fluid="md">
                <Row className="content">
                    <Col className="hero-image before" lg={0} md={6}>
                    </Col>
                    <Col className="hero-content" md={6}>
                        <h1>Cloud costs slowing you down? Save with Calculated Cloud!</h1>
                        <p>Optimize, protect, and reduce infrastructure costs by utilizing our team of experts to improve your existing cloud deployments to solutions like AWS, Azure, GCP, and more!</p>
                        <Row>
                            <Col><Button href='#contact'>Contact Us</Button></Col>
                        </Row>
                    </Col>
                    <Col className="hero-image after" xs={0} sm={6} md={6} lg={6}>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}