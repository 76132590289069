import './footer.css';
import { Col, Container, Image, Row } from "react-bootstrap";
import logo from '../../images/logo-white.svg';

export function Footer() {
    return (
        <footer>
            <Container>
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col sm={2} className="image-col">
                                <Image src={logo} width="196" height="43" />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="copyright">&copy; 2021 Calculated Cloud, LLC</Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}