import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useState } from 'react';
import './contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const isDev = false;

export function Contact() {
    const [state, setState] = useState({ isSending: false });

    const schema = yup.object({
        name: yup.string().required(),
        email: yup.string().email().required(),
    }).required();
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = (d) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(d)
        };
        window.dataLayer.push({'event':'info_request'}); // google tag manager event
        setState({ isSending: true });
        fetch('https://api.calculatedcloud.com/v1/contact', requestOptions)
            .then(resp => {
                if(!resp.ok) {
                    throw resp;
                }
                setState(prevState => {
                    return {
                        ...prevState,
                        status: "success"
                    };
                });
                return resp
            })
            .catch(err => {
                setState(prevState => {
                    return {
                        ...prevState,
                        status: "error"
                    };
                });
                return err.json();
            })
            .then((json) => {
                setState(prevState => {
                    if(prevState.status = "success") {
                        reset(); // reset form
                    }
                    return {
                        ...prevState,
                        isSending: false,
                        message: json.message || "Your information has been received. We'll be in touch soon!"
                    };
                });
            })
    }
    return (
        <section id="contact" className="contact">
            <Container>
                <Row>
                    <Col sm={6} className="form-container">
                        <h4>Contact Us</h4>
                        <p>Have a question or ready to get started? Fill out your information below and a member of our team will respond to your message promptly!</p>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label>Your Name</Form.Label>
                                <Form.Control type="text" className={`${errors.name ? 'error' : ''}`} {...register("name")} defaultValue={`${isDev ? 'Geoff Lancaster' : ''}`} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Your Email</Form.Label>
                                <Form.Control type="email" className={`${errors.email ? 'error' : ''}`} {...register("email")} defaultValue={`${isDev ? 'Geoff@test.com' : ''}`} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formRequest">
                                <Form.Label>How can we help you?</Form.Label>
                                <Form.Control as="textarea" rows="1" {...register("message")}/>
                            </Form.Group>
                            <div className="errorMsg">
                                {Object.keys(errors).map((name, idx) => capitalizeFirstLetter(errors[name]?.message, idx))}
                            </div>
                            <Button variant="primary" type="submit" disabled={state.isSending}>
                                {!state.isSending ? 'Send' : <FontAwesomeIcon icon={faSpinner} />}
                            </Button>
                            <div className={`${state.status ? state.status : ''}Msg`}>
                                {state.message}
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

function capitalizeFirstLetter(string, idx) {
    if (!string) return;
    return (
        <div key={idx}>
            {string.charAt(0).toUpperCase() + string.slice(1)}
        </div>
    );
}
