import './App.css';

import React from 'react';
import { Nav } from './sections/nav/nav';
import { Hero } from './sections/hero/hero';
import { Services } from './sections/services/services';
import { About } from './sections/about/about';
import { Contact } from './sections/contact/contact';
import { Footer } from './sections/footer/footer';

let sections = [Hero, Services, About, Contact];

function App() {
  return (
    <div className="App">
      <Nav />
      <div>
        {sections.map((Component, key) => (<Component key={key} />))}
      </div>
      <Footer/>
    </div>
  );
}

export default App;
